/* Main container for the form */
.form-detail-main {
    width: auto;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
}
/* Box containing the form */
.form-detail-box {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px #192b44;
    margin-bottom: 20px;
    margin-top: 10px;
    margin: 10px;
}
/* Title of the form */
.form-detail-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}
/* Rows and columns for form layout */
.form-detail-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.form-detail-col {
    flex: 1 1 45%;
    /* Adjust width of columns for responsiveness */
}
/* Labels for the form fields */
.form-detail-label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
    font-size: 13px;
}
/* Input fields for the form */
.form-detail-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    width: 100%;
    box-sizing: border-box;
}
/* Submit button for the form */
.form-detail-submit {
    padding: 10px;
    background-color:black;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}
.form-detail-submit:hover {
 background-color: rgb(48, 45, 45);
}
/* Main container for the todo list (table format) */
.todo-container {
    width: 100%;
    margin-top: 20px;
}
/* Heading for the todo list */
.todo-heading {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}
/* Table for displaying form details */
.todo-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 0px;
}
.todo-table th,
.todo-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap;
}
.todo-table th {
    background-color: rgba(210, 208, 208, 0.7) !important;
    color: black !important;
    font-weight: bold;
}
.todo-table tr:nth-child(even) {
    background-color: #f9f9f9;
}
.save-btn {
    width: 200px;
    background: green;
    color: white;
    border: none;
    padding: 10px;
    margin-top: 20px;
}
.save-btn:hover{
    background-color: rgb(3, 78, 3);
    transition: 0.3s;
}
.delete-btn {
    background: red;
    border: none;
    padding: 10px;
    width: 80px;
    height: 40px;
    color: white;
}
.edit-btn:hover {
    background-color: #02544c;
    transition: 0.5s;
}
.delete-btn:hover {
    background-color: rgb(149, 4, 4);
    transition: 0.5s;
}

@media (max-width:600px) {
    .form-detail-main {
        margin-left: 0px;
    }
}