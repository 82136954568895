.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  /* Black background */
}

.auth-box {
  background: #fff;
  /* White background */
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.1);
  /* Light shadow */
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #000;
  /* Black text */
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #000;
  /* Black text */
}

.form-group input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  /* Light grey border */
  border-radius: 4px;
  background-color: #fff;
  /* White background */
  color: #000;
  /* Black text */
}

.form-group input::placeholder {
  color: #888;
  /* Grey placeholder text */
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #000;
  /* Black background */
  border: none;
  color: white;
  /* White text */
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-btn:hover {
  background-color: #333;
  /* Darker black */
}

.switch-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: #000;
  /* Black text */
  cursor: pointer;
  font-size: 14px;
}

.switch-btn:hover {
  text-decoration: underline;
}