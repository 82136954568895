/* Table Styles */
.table-main-box {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  height: auto;
  overflow-x: auto;
  margin-bottom: 10px;
}

.table-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #192b44;
}

.table-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.main-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 0px;
}

.main-table th,
.main-table td {
  padding: 8px;
  text-align: center;
  white-space: nowrap;
}

.main-table th {
  background-color: black;
  font-weight: bold;
  color: white;
}

.table-responsive {
  overflow-x: auto;
  border: 1px solid;
}

/* saparate color for tr */
.main-table tbody tr:nth-child(even) {
  background-color: rgba(239, 238, 238, 0.7);
}

.main-table tbody tr:hover {
  background-color:rgba(239, 238, 238, 0.7);
}
/* Adjust margin-top for mobile sizes */
@media (max-width: 768px) {
  .table-main-box {
    margin-left: 0px;
    max-width: 100%;
  }
}

.editBtn:hover{
  background-color: black;
  color: white;
}


.view-btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.back-button {
  margin-bottom: 15px;
  padding: 8px 16px;
  background-color: rgb(59, 58, 58);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color:black;
}

