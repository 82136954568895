/* Form Styles */
.add-form-main-box {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px black;
  border-radius: 8px;
  height: 55rem;
  margin-bottom: 10px;
  margin-top: 10px;
}
.form-detail-main {
  height: 50rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.add-form-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.add-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.add-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
@media (min-width: 768px) {
  .add-form {
    grid-template-columns: 1fr 1fr;
  }
}
/* Adjust margin-top for mobile sizes */
@media (max-width: 600px) {
  .add-form-main-box {
    max-width: 450px;
  }
}
@media (max-width: 768px) {
  .form-details-main-box {
    margin-top: 250px;
  }
}
.add-form-label {
  grid-column: span;
  font-weight: bold;
  margin-bottom: 4px;
}
.add-form-input,
.add-form-textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.add-form-textarea {
  grid-column: span;
  min-height: 100px;
}
.add-form-submit-btn {
  grid-column: span 2;
  background-color: black;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}
.add-form-submit-btn:hover {
  background-color: rgb(49, 42, 42);
}
/* Additional Styles */
.form {
  margin-bottom: 20px;
}
.table {
  margin-top: 20px;
}