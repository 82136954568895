@page {
  size: A4 landscape;
  margin: 10mm;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.table-container {
  direction: rtl;
  width: 100%;
  height: calc(110vh - 20mm); /* Use full height of A4 minus margins */
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* Ensures columns do not resize automatically */
  max-width: 100%;
  flex: 1; /* Allow the table to take up available space */
}

.header-title {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  border: 1px solid #192b44;
  font-size: 14px;
  background-color: black;
  color: white;
}

.sub-header {
  font-size: 12px;
  margin-top: 2px;
}

.section-header {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  border: 1px solid #192b44;
  font-size: 12px;
}

.field-names {
  text-align: center;
  font-weight: bold;
}

.field-names th {
  border: 1px solid #192b44;
  padding: 3px;
  white-space: normal;
  font-size: 10px;
  word-wrap: break-word;
}

.table-cell {
  border: 1px solid #192b44;
  padding: 3px;
  text-align: center;
  font-size: 13px;
  white-space: normal;
  word-wrap: break-word;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #192b44;
  box-sizing: border-box;
}

.footer-left,
.footer-right {
  flex: 1;
  padding: 5px;
  border: 1px solid #192b44;
}

.footer-left p,
.footer-right p {
  margin: 2px 0;
  font-size: 10px;
  white-space: normal;
  word-wrap: break-word;
}

.footer-signatures {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #192b44;
  box-sizing: border-box;
}

.footer-signature {
  flex: 1;
  padding: 5px;
  border: 1px solid #192b44;
  text-align: center;
}

.footer-signature p {
  margin: 2px 0;
  font-size: 10px;
  white-space: normal;
  word-wrap: break-word;
}

/* Styles for print */
@media print {
  .print-button-container {
    display: none;
  }

  .table-container {
    margin: 0;
    height: calc(110vh - 20mm); /* Ensure container uses full A4 height */
  }

  .custom-table {
    margin-bottom: 20px; /* Add space for footer if necessary */
  }
}

.print-button-container {
  text-align: center;
  margin-top: 20px;
}



/* From Uiverse.io by vinodjangid07 */ 
.Btn {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: rgb(27, 27, 27);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
  float: right;
  margin-right: 110px;
  margin-bottom: 10px;
}

.svgIcon {
  fill: rgb(214, 178, 255);
}

.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 2px solid rgb(182, 143, 255);
  border-left: 2px solid rgb(182, 143, 255);
  border-right: 2px solid rgb(182, 143, 255);
}

.tooltip {
  position: absolute;
  right: -105px;
  opacity: 0;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: .2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}

.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(12, 12, 12);
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  left: -5%;
  transition-duration: .3s;
}

.Btn:hover .tooltip {
  opacity: 1;
  transition-duration: .3s;
}

.Btn:hover {
  background-color: rgb(150, 94, 255);
  transition-duration: .3s;
}

.Btn:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

.Btn:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

